<template>
    <div class="index-page">
    <div class="bind-page " v-if="!hidePage">
        <div class="bind-box ">
            <div class="bind-out">
                <div class="title">以何种身份登陆？</div>
                    <div v-for="(item,index) in roleList" :key="index" class="item-box">
                        <div :title="item.rolename" @click="onClickRole(item)" class="role">{{item.rolename}}</div>
                        <div class="info">
                            <span :title="`${item.platformname}_${item.zonename}`" class="name">{{`${item.platformname}_${item.zonename}`}}</span>
                            <span class="account">{{realName}}</span>
                        </div>
                    </div>
            </div>
        </div>
    </div>
        <!-- <div v-loading="loading" class="loading"></div> -->
    </div>
</template>

<script>
import {
    Hasmartedu_GET_Session,
    NEW_BING_ACCOUNT_SESSION,
    GETACCOUNTROLE
} from '@/http/api.js';
export default {
    name: 'Auth',
    data() {
        return {
            roleList:[],
            realName:'',
            hidePage:true,
            loading: true, // loading
            state: '',
            isDebug: '',
            result: 0,// 0 成功， 1， 失败;
            error: '', // 是一个字符串错误提示内容；
            session: ''
        }
    },
    created() {
        const code = this.$route.query.code;
        // 通过返回的code来获取session


        this.getSessionByCode(code);
    },
    methods: {

        getRoleList(){
            let hostName = window.location.hostname
            if(hostName.indexOf('localhost')!=-1){//外测
                hostName = 'test-account.forclass.net'//外正地址
            }
            let param = {
                session:this.session,
                hostName: hostName
            }
            GETACCOUNTROLE(this.$qs.stringify(param)).then((result)=>{
                result = result.data.result
                if(this.isValid(result)){
                    this.roleList = this.uniqueRoleList(result[0])
                    if(this.roleList.length == 1){
                        let obj = {
                            platformid:this.roleList[0].platformid,
                            roleid:this.roleList[0].roleid,
                            zoneid:this.roleList[0].zoneid,
                            schoolid:this.roleList[0].schoolid,
                        }
                        this.onClickRole(obj)
                    }else{
                        this.hidePage = false
                        if(this.isValid(result[0].realname)){
                            this.realName = result[0].realname
                        }else if(this.isValid(result[0].account)){
                            this.realName = result[0].account
                        }
                    }
                }else{
                    this.$message('角色列表获取失败')
                }
            }, this, true)
        },
        uniqueRoleList(data){
            let arr = []
            let uniqueList = []
            data.roles.forEach(element => {
                let name = `${element.platformid}_${element.roleid}_${element.zoneid}_${element.schoolid}`
                console.log(name)
                if(!arr.includes(name)){
                    arr.push(name)
                    uniqueList.push(element)
                }
            });
            return uniqueList
        },
        onClickRole(item){
            let param = {
                session:this.session,
                platformId:item.platformid,
                roleId:item.roleid,
                zoneId:item.zoneid,
                schoolId:item.schoolid,
            }
            NEW_BING_ACCOUNT_SESSION(this.$qs.stringify(param)).then((result)=>{
                result = result.data;
                if(result.ReturnCode == 1){
                    console.log('绑定成功')
                    this.hidePage = true
                    this.gotoUrl()
                }else{
                    this.$message('请求失败')
                }
            }, this, true)
        },
        async getSessionByCode(code) {
            try {
                this.state = this.$route.query.state || localStorage.getItem('Hasmartedu') || '';
                this.isDebug = this.$route.query.isDebug;
                const res = await Hasmartedu_GET_Session({ code });
                let data = res.data || {};


                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }

                console.log('返回的参数：', data);

                this.session = '';
                this.error = ''; // 是一个字符串错误提示内容；
                this.result = 0; // 0 成功， 1， 失败;
                if (data.ReturnCode == 1) {
                    /**
                     * 反馈客户端：
                     * result : 0 成功， 1， 失败;
                     * error: 是一个字符串错误提示内容；
                     * */
                     this.session = data.result[0];
                     this.error = data.ReturnText || '';
                } else {
                    this.result = 1;
                    this.session = '';
                    this.error = data.ReturnText || '登录失败';
                }

                if (this.result === 0) {
                    this.getRoleList()
                } else {
                    this.$message.error(this.error ? this.error : '获取session失败');
                }
            } catch (error) {
                // 报错了，重新跳转回登录页面
                console.log('发生错误：', error);
                this.$message.error('无法获取登录信息，请稍后重试');
            }

            this.bus.$emit('loading', false);
        },
        gotoUrl(){
            const env = this.getEnv();
            let url = '';
            // 有state参数，且是一个合法的url
            if (this.state && /^(https?:\/\/)/.test(decodeURIComponent(this.state))) {
                url = `${decodeURIComponent(this.state)}?session=${this.session}`;
            } else if (this.state == 'win' || this.state == 'android') {
                if (window.CallCPP) {
                    window.CallCPP('<WebLogin result="' + this.result + '" session="' + this.session + '" error="' + this.error + '"></WebLogin>');
                }
                if (window.HostApp) {
                    window.HostApp.alert({ "cmd": "Sessionlogin", "session": this.session });
                }
            } else {
                //备课
                if(this.state == 'bk'){//bk  最终跳转到 https://edu-jzjx.hasmartedu.com/About/Intelligence
                    url = `${env == 'test' ? 'https://test-xz.forclass.net/About/Intelligence' : 'https://edu-jzjx.hasmartedu.com/About/Intelligence'}?session=${this.session}`;
                } else if(this.state == 'zy'){//zy  最终跳转到 https://res-jzjx.hasmartedu.com/Res/LectureResources
                    url = `${env == 'test' ? 'https://test-xz.forclass.net/Res/LectureResources' : 'https://res-jzjx.hasmartedu.com/Res/LectureResources'}?session=${this.session}`;
                } else {
                    url = `${env == 'test' ? 'https://test-xz.forclass.net' : 'https://edu-jzjx.hasmartedu.com'}?session=${this.session}`;
                }
                
            }

            // 是否开启调试模式【不跳转页面】
            if (this.isDebug === 'true') {
                console.log('当前跳转的地址：', url);
            } else if(url){
                location.href = url;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    

    .loading {
        width: 100%;
        height: 100%;
    }
    
    .bind-page
    {
        width: 100%;
        color: #000;
    }

    .bind-box
    {
        max-width: 660px;
        margin: 50px auto;
    }
    .bind-out
    {
        width:100%;
        display:inline-block;
        background-color: #fff;
        position: relative;
        padding: 0 5%;
        box-sizing: border-box;
    }
    .title{
        font-size: 16px;
        border-bottom: 1px solid #bbb;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .item-box{
        font-size: 14px;
        display: flex;
        min-height: 50px;
        border-bottom: 1px solid #bbb;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .role{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #169fe6;
        border-radius: 20px;
        color: #FFF;
        cursor: pointer;
        user-select: none;
        min-width: 80px;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 20px;
        white-space: nowrap;
    }
    .info{
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        width: 60%
    }
    .name{
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }
    .cover{
        width: 100vw;
        height: 100vh;
        background: #FFF;
        z-index: 1;
        position: fixed;
        top: 0;
    }
}
// H5移动端
@media screen and (max-width: 600px) {
    .role{
        width: 100px !important;
    }
}
</style>